import React from "react";
import "./TechStack.css";


import flutter from "../../Assets/PNG/tech-icon/flutter.png";

import canva from "../../Assets/PNG/tech-icon/canva.png";

import next from "../../Assets/PNG/tech-icon/next-js.png";

import figma from "../../Assets/PNG/tech-icon/figma.png";

import reactIcon from "../../Assets/PNG/tech-icon/react.png";

import shopify from "../../Assets/PNG/tech-icon/Shopify.png";

import webflow from "../../Assets/PNG/tech-icon/webflow.jpg";

import wix from "../../Assets/PNG/tech-icon/wix.png";

import jsIcon from "../../Assets/PNG/tech-icon/js.png";

import adobe from "../../Assets/PNG/tech-icon/illustrator.png";


import inkscape from "../../Assets/PNG/tech-icon/inkscape.png";

import salesforceLogo from "../../Assets/PNG/tech-icon/salesforce-logo.png";

import nodejs from "../../Assets/PNG/tech-icon/Nodejs.png";




const TechStack = () => {

    return (
        <>
            <div className="tech-stack-container" id="technologies">

                <div className="tech-info-container">
                    <p className="tech-info-title">Our Technologies</p>
                    <p className="tech-info-desc">At Stacktically Inc, we leverage cutting-edge technology to drive innovation and deliver exceptional solutions. </p>
                </div>

                <div className="grid-container grid-container-1">
                    <div><img src={figma} alt="figma logo"/></div>
                    <div><img src={canva} alt="canva logo"/></div>  
                    <div><img src={next} alt="next logo"/></div>
                    <div><img src={webflow} alt="webflow logo"/></div>
                   
                </div>
                <div className="grid-container grid-container-2">
                    <div><img src={flutter} alt="flutter logo"/></div>
                    <div><img src={adobe} alt="adobe logo" /></div>  
                    <div><img src={reactIcon} alt="react logo"/></div>
                    <div  className="wix-image"><img src={wix} alt="wix logo"/></div>

                    <div><img src={jsIcon} alt="JS logo"/></div>
                </div>

                <div className="grid-container grid-container-3">
                    <div><img src={shopify} alt="shopify logo"/></div>
                    <div><img src={inkscape} alt="inkscape logo"/></div>  
                    <div><img src={salesforceLogo} alt="salesforce logo"/></div>
                    <div><img src={nodejs} alt="nodejs logo"/></div>

                </div>
            </div>
        </>
    )
}


export default TechStack