import React from 'react'
import './Service.css'

const ServiceCard = ({IMG , title , description}) => {
  return (
    <>
      <div className="service-card">
        <div className="service-card-header-center">
          <div className="service-card-heading">
              <img src={IMG} alt="service icons" />
          </div>
        </div>

        <div className="service-card-info">
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
      </div>
    </>
  )
}

export default ServiceCard
