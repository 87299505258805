import {db} from "../firebase-config";

import { collection , getDoc , getDocs , addDoc , doc} from "firebase/firestore";

const userCollectionRef = collection(db , "user");

class addUser{
    addUserData = (email) =>{
        return addDoc(userCollectionRef , {email: email});
    }
}

export default new addUser();