import React from 'react'
import "./WhyChoose.css"
import { FcCollect, FcMindMap, FcDoughnutChart } from "react-icons/fc";

const WhyChoose = () => {

  const points = [
    {
      icon: FcCollect,
      title: "Expert Team",
      description: "Our experienced professionals are dedicated to delivering top-notch solutions.",
    },
    {
      icon: FcMindMap,
      title: "Innovative Approach",
      description: "We embrace cutting-edge technologies to provide creative and effective outcomes.",
    },
    { icon: FcDoughnutChart,
      title: "Client Satisfaction",
      description: "Our primary focus is to ensure our clients' success and satisfaction.",
    },
  ];

  return (
    <div className="why-choose-us" id='whychoose'>
      <h2>Why Choose Us</h2>
      <div className="card-container">
        {points.map((point, index) => (
          <div className="card" key={index}>
            <h3>< point.icon size={"2em"}/></h3>
            <h3>{point.title}</h3>
            <p>{point.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WhyChoose