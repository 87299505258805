import {db} from "../firebase-config";

import { collection , getDoc , getDocs , addDoc , doc} from "firebase/firestore";

const contactUserCollectionRef = collection(db , "contacts");

class addDetails{
    addContact = (newContact) =>{
        return addDoc(contactUserCollectionRef , newContact);
    }
}

export default new addDetails();
