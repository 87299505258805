import "./App.css";

import Client from "./Pages/Client/Client";
import Contact from "./Pages/Contact/Contact";
import Footer from "./Pages/Footer/Footer";
import Hero from "./Pages/Hero/Hero";
import Navigation from "./Pages/Navigation/Navigation";
import WhyChoose from "./Pages/why/WhyChoose";

import Service from "./Pages/Service/Service";
import Testimonial from "./Pages/Testimonial/Testimonial";
import TechStack from "./Pages/TechStack/TechStack";

import PreLaunch from "./Pages/PreLaunch/PreLaunch";

function App() {
  return (
    <div>
      {/* <PreLaunch/> */}
      <Navigation />
      <Hero />
      <WhyChoose />
      <Service />
      <Testimonial />
      <TechStack />
      <Contact />
      {/* <Client /> */}
      <Footer />
    </div>
  );
}

export default App;
