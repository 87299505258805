import React, { useState } from "react";
import "./Hero.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import character from "../../Assets/PNG/illustrator.png";
import addUser from "../../Services/addUser";
const Hero = () => {
  const [userEmail, setUserEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    addUser.addUserData(userEmail);
  };

  return (
    <div className="layout-hero">
      <header className="header-container">
        <div className="main-container">
          <div className="info-container">
            <h1 className="info-title">
              Quality tech services for your business{" "}
            </h1>
            <p className="info-para">
              We make it easy for you to share your ideas and move towards
              making them a reality.
            </p>
            <p>Think Tactically, Think Stacktically !</p>
            <div className="header-input-field">
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  name="useEmail"
                  id="useEmail"
                  required
                  placeholder="Enter Your Email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </form>
              <button className="get-started-btn" type="submit">
                Get Started
              </button>
              <button className="header-send-btn">
                <FontAwesomeIcon icon={faPaperPlane} size="2x" />
              </button>
            </div>
          </div>

          <div className="main-character">
            <img src={character} alt="hero img " />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Hero;
