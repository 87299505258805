import React from 'react'
import "./Testimonial.css";
import { RiArrowRightUpLine } from "react-icons/ri";
import { CgQuote } from "react-icons/cg";
import pdf from "../../Assets/freshsmiles-casestudy.pdf"


const TestimonialCard = ({description , image , personNo , personOccupation}) => {
  
  return (
    <>
      <div className="testimonial-card">
        <div>
          <div className="testimonial-card-header">
            <div className="testimonial-card-heading">
              <a href={pdf} target='_blank' rel='noreferrer'>
              Case Study <span><RiArrowRightUpLine size="1.5rem"/></span>
              </a>
            </div>

          </div>
          
          <div className="testimonial-card-review">
              <CgQuote size="3em" className='quote-icon1'/>
              <p>{description}</p>
              <CgQuote size="3em" className='quote-icon2' style={{}}/>
          </div>
        </div>

        <div className="testimonial-footer">
          <div className="testimonial-footer-left">
            <img src={image} alt="profile icons" />

            <div className="testimonial-footer-info">
              <h3>{personNo}</h3>
              <p>{personOccupation}</p>
            </div>
          </div>

        
        </div>
      </div>
    </>
  )
}

export default TestimonialCard
