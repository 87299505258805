import React , { useState }  from 'react'
import './Contact.css'
import addDetails from "../../Services/addDetails";
import { RxCross2 } from "react-icons/rx";


const Contact = () => {

  const [userData , setUserData] = useState({
    name : '',
    email : '',
    phone : '',
    message : ''
  })

  const [message , setMessage] = useState({error:false , message:""});
  const [emailError , setEmailError] = useState("");
  const [phoneError , setPhoneError] = useState("");
  const [showAlert , setShowAlert] = useState(false);

  const handleAlertClose = () =>{
    setShowAlert(false);
  };

  
  const handleData = (event) => {
    const {name , value} = event.target;
    setUserData({...userData , [name] : value});

    if(name === "email"){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(value)){
        setEmailError("Invalid Email");
      }else{
        setEmailError("");
      }
    }

    if(name === "phone"){
      const phoneRegex = /^\d{10}$/;
      if(!phoneRegex.test(value)){
        setPhoneError("Invalid Phone Number");
      }else{
        setPhoneError("");
      }
    }

    setMessage({error:false , message:""});
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    if(userData.name === "" || userData.email === "" || userData.phone === "" || userData.message === ""){
      setMessage({error:true , message:"All fields are required"});
      return;
    }


    try{
      await addDetails.addContact(userData);
      setMessage({error:false , message:"Contact Added Successfully"});
      setUserData({
        name : '',
        email : '',
        phone : '',
        message : ''
      })
      setShowAlert(true);

    }
    catch(err){
      setMessage({error:true , message:err.message});
    }

  }


  return (
    <>
    <div className="contact-main-container" id="contact">

    
        <div className='container' >
          <div className='contactInfo'>
            <h2>Let's Connect</h2>
            <p>We are here to help you grow your business</p>
            <ul className='info'>
              {/* <li><span><i className="fa-solid fa-phone"></i></span>+123-456-789</li> */}
              <li><span><i className="fa-solid fa-envelope"></i></span>hi@stacktically.com</li>
            </ul>

            <ul className='social-media'>
              <li><a><i className="fa-brands fa-facebook"></i></a></li>
              <li><a><i className="fa-brands fa-instagram"></i></a></li>
              <li><a><i className="fa-brands fa-linkedin"></i></a></li>
            </ul>
          </div>

          <div className='contactForm'>
            <form onSubmit={handleSubmit} >

              <div className='formBox'>
                  <div className='inputBox w50'>
                    <span>Full Name</span>
                    <input type='text' name='name' value={userData.name} onChange={handleData} />
                  </div>

                  <div className='inputBox w50'>
                  {/* <span>Last Name</span>
                  <input type='text' required />
                  </div>
                  <div className='inputBox w50'> */}
                    <span>Email</span>
                    <input type='text' name='email' value={userData.email} onChange={handleData}/>
                    {emailError && <span style={{color:"red"}}>{emailError}</span>}
                  </div>

                  <div className='inputBox w50'>
                    <span>Phone Number</span>
                    <input type='number' className='number-to-text' name='phone' value={userData.phone} onChange={handleData} />
                    {phoneError && <span style={{color:"red"}}>{phoneError}</span>}
                  </div>

                  <div className='inputBox w100'>
                    <span>Message</span>
                    <textarea type='text' placeholder='Write your Message' name='message' value={userData.message} onChange={handleData}></textarea>
                  </div>

                  <div className='button w50'>
                    <button type='submit' value='Send'>Get in touch</button>
                  </div>
                </div>
                {message.error && <span style={{color:"red"}}>{message.message}</span>}
            </form>
          </div>
        </div>

        {showAlert && (
          <div className='custom-alert'>
            <p>{message.message}</p>
            <RxCross2 size="1.5rem" className='cross-icon' onClick={handleAlertClose}/>
          </div>
        )}
    </div>  
    </>
  )
}

export default Contact