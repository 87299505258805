import React from 'react'
import "./Footer.css"
import footerLogo from "../../Assets/PNG/stacktically-logo-dark.svg";
import { BsInstagram, BsFacebook, BsLinkedin } from "react-icons/bs";

const Footer = () => {
    return (
        <>
            <footer className="footer-container" id='footer'>
                <div>
                    <div className="footer-part1">

                        <div className="footer-logo">
                            <img src={footerLogo} alt="Footer Logo !" />
                        </div>

                        <div className="footer-link">
                            <nav>
                                <ul>
                                    <li> <a href="/#">About Us</a> </li>
                                    <li> <a href="#services">Services</a> </li>
                                    <li> <a href="#technologies">Technologies</a> </li>
                                    <li> <a href="#testimonial">Testimonial</a> </li>
                                    <li> <a href="#contact">Contact</a> </li>
                                </ul>
                            </nav>
                        </div>

                    </div>

                    <div className="footer-part2">
                        <div className="footer-info">
                            <p>Quality tech services for <br /> your business</p>
                        </div>

                        <div className="footer-social-media-icon">
                            <p className='footer-email'>hi@stacktically.com</p>
                            <a href="/#"> <BsInstagram className='social-icon' /> </a>
                            <a href="/#"> <BsFacebook className='social-icon' /> </a>
                            <a href="/#"> <BsLinkedin className='social-icon' /> </a>
                        </div>
                    </div>

                </div>
                <hr className="brake-line" />
                <div className="footer-part3">
                    <p>&copy;Stacktically. All right reserved</p>
                </div>
            </footer>
        </>
    )
}

export default Footer
