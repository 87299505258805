import React from "react";
import "./Testimonial.css";
import TestimonialCard from "./TestimonialCard";
import profilepic1 from "../../Assets/PNG/testimonial-icon/female.jpg";
import profilepic2 from "../../Assets/PNG/testimonial-icon/male.jpg";
import "react-multi-carousel/lib/styles.css";

const Testimonial = () => {
  const TestimonialData = [
    {
      id: 1,
      description:
        "I want to express my gratitude for each and every one of you for the great work you've done in redesigning our dental website.  The transformation is incredible, and we couldn't be happier with the final result.",
      image: profilepic1,
      personNo: "Dr. Somayeh Jahedi",
      personOccupation: "Fresh Smiles Dental",
    },

    {
      id: 2,
      description:
        "Team Stacktically is very technical and methodical in their approach. They delivered my project on time and with superior quality.",
      image: profilepic2,
      personNo: "Vinyak Mundayat",
      personOccupation: "",
    },
  ];

  return (
    <>
      <section className="testimonial-container" id="testimonial">
        <div className="testimonial-heading">
          <h2>What customers say about Us</h2>
        </div>

        <div className="testimonial-cards-container">
          {TestimonialData.map((item) => {
            return <TestimonialCard {...item} key={item.id} />;
          })}
        </div>
      </section>
    </>
  );
};

export default Testimonial;
