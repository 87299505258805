import React, { useRef } from 'react'
import './Navigation.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars , faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from '../../Assets/PNG/stacktically-logo-dark.svg'

const Navigation = () => {

    const nevRef = useRef();

    const showNavbar = () => {
        nevRef.current.classList.toggle("responsive_nav");
    }

  return (
    <div className='layout-nav'>
        <nav className='navbar-container'>

            <div className="navbar-logo">
                <a href="/#"><img src={logo} alt="Stacktically Logo"></img></a>
            </div>

            <div className="nav-container">
                <ul className='navbar' ref={nevRef}>

                    <li> <a href="#whychoose">Why Us</a> </li>
                    <li> <a href="#services">Services</a> </li>
                    <li> <a href="#testimonial">Testimonial</a> </li>
                    <li> <a href="#technologies">Technologies</a> </li>
                    <li> <a href="#contact">Contact</a> </li>


                    <button className='menu-btn menu-close-btn' onClick={showNavbar}>
                        <FontAwesomeIcon icon={faTimes}  />
                    </button>
                </ul>
            </div>

            <div>
                {/* <button className='navbar-btn'> <a href="#contact">Get Started</a></button> */}

                <a href="#contact"><button className='navbar-btn'>Get Started</button></a>
            </div>

            <button onClick={showNavbar} className='menu-btn'>
                <FontAwesomeIcon icon={faBars} />
            </button>
        </nav>

    </div>
  )
}

export default Navigation
