import React from 'react'
import './Service.css'
import ServiceCard from './ServiceCard';
import mobile from "../../Assets/PNG/Mobile.png";
import details from "../../Assets/PNG/Details.png";
import web from "../../Assets/PNG/Web.png";
import informationTechnology from "../../Assets/PNG/information-technology.png";
import saleforce from "../../Assets/PNG/Salesforce.png";
import cloud from "../../Assets/PNG/cloud-data 1.png";

const serviceCardData = [
    {
        id:1,
        IMG:mobile,
        title:"App Development",
        description:"Transform your ideas into fully functional and innovative mobile applications with our app development services.",
    },

    {
        id:2,
        IMG:details,
        title:"UI/UX Design",
        description:"Our UI/UX design services focus on creating user-centered and visually appealing interfaces that enhance user engagement and satisfaction.",
    },

    {
        id:3,
        IMG:web,
        title:"Web Development",
        description:"Our web development services focus on creating dynamic and responsive websites that captivate audiences and drive online success.",
    },

    {
        id:4,
        IMG:informationTechnology,
        title:"IT Services",
        description:"We provide end-to-end solutions from infrastructure setup and maintenance to network security and system optimization, we offer a wide range of services to ensure your business operates efficiently and securely.",
    },

    {
        id:5,
        IMG:saleforce,
        title:"Enterprise Software",
        description:"Our enterprise software solutions cater to the unique needs of your organization, providing scalable and tailored CRM, ERP, Payroll implementations that address complex business challenges.",
    },

    {
        id:6,
        IMG:cloud,
        title:"DevOps",
        description:"We implement best practices in continuous integration, continuous delivery, and automation to help your development teams deploy and run code efficiently and with minimal risk in the cloud.",
    },
]
const Service = () => {
  return (
    <div className='layout-service'>
      <div className="service-container" id='services'>
        <div className="service-header">
            <h2>Our Services</h2>
            <p>We help you achieve your goals</p>
        </div>


        <div className="service-cards-container">
            {
                serviceCardData.map(items => (
                    <ServiceCard {...items} key={items.id}/>
                ))
            }
        </div>

      </div>
    </div>
  )
}

export default Service
